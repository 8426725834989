import React from 'react';
import Layout from '../components/Layout/Layout';
import { Link } from 'gatsby';
import Seo from '../components/SEO/SEO';

const errorPage = () => (
  <>
  <Layout headlines={["Zu verkaufen: tiny-house-bauen.de"]} className="centeredPage--TextPage">
    <h1>Domain zu verkaufen</h1>
    <p>Die Domain <i>tiny-house-bauen.de</i> steht zum Verkauf bereit. Bei Interesse
    kann gerne das <Link to="/kontakt/">Kontaktformular</Link> benutzt weden.
    </p>
    <p>Ursprünglich wollte ich diese Domain verwenden, aber kam bis dato nicht dazu.
      Daher verkaufe ich sie gerne weiter an jemanden, der sie verwenden möchte. </p>
  </Layout>
  </>
);

export default errorPage;

export function Head() { 

  return(
    <Seo 
      title = 'Domain zu verkaufen | Tiny House Helden'
    />
  )
}